import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '~/components/Layout'
import { Post } from '~/classes/Post'
import { PostView } from '~/components/PostPreview'
import { useApp } from '~/providers/AppProvider'

interface PostTemplatePageContext {
  urlKey: string
  urlPath: string
}

interface PostTemplateProps extends PageProps {
  data: Queries.PostTemplateQuery
  pageContext: PostTemplatePageContext
}

const PostTemplate: React.FC<any> = ({ data, uri, pageContext }) => {
  const post = new Post(data.markdownRemark)
  const app = useApp()
  if (!app) return
  return (
    <Layout>
      <PostView post={post} />
    </Layout>
  )
}
export default PostTemplate

export const query = graphql`
  query PostTemplate($urlPath: String!) {
    markdownRemark(fields: { urlPath: { eq: $urlPath } }) {
      ...PostDataFragment
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
